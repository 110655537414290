<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto content-outer-container">
      <div class="mx-auto mainContainer wrap-earnList">
        <div class="titleContainer">
          <div class="title">
            <div class="textTitle">
              <img
                class="mr-2"
                style="vertical-align: sub"
                src="@/assets/images/planreward/Fire.svg"
                alt=""
              />{{ lbl['plan-reward-point-earn-tab'] }}
            </div>
          </div>
          <div class="search d-flex">
            <div class="searchContainer">
              <a-input
                type="text"
                class="searchInput"
                v-model="searchInput"
                @keyup.enter="onEnterInput($event)"
                @change="onChangeInput($event)"
                autocomplete="off"
              />
              <img
                @click="onEnterInput($event)"
                :src="require('@/assets/images/Search.png')"
                alt=""
              />
            </div>
            <a-button
              type="primary"
              :disabled="disableCreatePointSetup"
              @click="createNew()"
            >
              <img
                :src="require('@/assets/images/btn/plus-square.svg')"
                class="mr-2"
                alt=""
              />
              {{ lbl['plan-reward-point-earn-list-add-button'] }}
            </a-button>
          </div>
        </div>
        <hr />
        <div class="filterContainer nopad mt-4">
          <div :class="['filterMain', showFilterMain ? 'Active' : '']">
            <div class="filter" @click="toggle('showFilterMain')">
              {{ lbl['user-permissions-filter'] }}
              <img :src="require('@/assets/images/filter_list.png')" alt="" />
            </div>
            <div class="spaceHover"></div>
            <div class="filterDropdown">
              <div class="filterLeft">
                <div
                  :class="['filterList', item === subFilter ? 'Active' : '']"
                  v-for="item in Object.keys(filter)"
                  @click="changeSubFilter(item)"
                  :key="item"
                >
                  {{ filter[item].label }}
                  <img
                    :src="require('@/assets/images/navigate_next.png')"
                    alt=""
                  />
                </div>
              </div>
              <div class="filterRight">
                <div v-for="item in filter[subFilter].input" :key="item">
                  <div
                    v-if="filter[subFilter].type === 'checkbox'"
                    class="filterList"
                    @click="selectFilter(item)"
                  >
                    <CheckBox
                      :label="item"
                      :value="item"
                      :check="filter[subFilter].value"
                      type="filter"
                    />
                  </div>
                  <div
                    v-if="filter[subFilter].type === 'text'"
                    class="filterList input"
                  >
                    <input
                      type="text"
                      :placeholder="item"
                      v-model="filter[subFilter].value"
                    />
                  </div>
                </div>
              </div>
              <div class="btnFilter">
                <div @click="resetFilter()">
                  {{ lbl['user-permissions-reset'] }}
                </div>
                <button @click="searchFilter()">
                  {{ lbl['user-permissions-ok'] }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="filterItem"
            v-for="(item, index) in Object.keys(allFilter)"
            :key="'filter' + index"
          >
            {{ allFilter[item]
            }}<img
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </div>
        </div>

        <div class="tableContainer wrap-table">
          <a-skeleton v-if="isLoading" active />
          <div v-else>
            <a-table
              :columns="columns"
              :data-source="data"
              rowKey="RowKey"
              :pagination="{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                pageSizeOptions: pageSizeOptions,
                pageSize: defaultPageSize,
                defaultCurrent: 1,
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: onChangePageCampaings,
              }"
              :customRow="openEdit"
            >
              <template slot="isdata" slot-scope="text, record">
                <div class="d-flex">
                  <span class="d-flex align-center">
                    <img
                      v-if="record.Status == statusActive"
                      src="@/assets/images/item-active-icon.png"
                      alt=""
                      class="item-status-icon mr-2"
                    />
                    <img
                      v-else-if="record.Status == statusWaiting"
                      src="@/assets/images/item-waiting-icon.png"
                      alt=""
                      class="item-status-icon mr-2"
                    />
                    <img
                      v-else
                      src="@/assets/images/item-inactive-icon.png"
                      alt=""
                      class="item-status-icon mr-2"
                    />
                  </span>
                  {{ record.Name ? record.Name : '' }}
                </div>
              </template>
              <template slot="date" slot-scope="text, record">
                {{
                  record.StartDate ? dateShortFormatUTC(record.StartDate) : ''
                }}
                -
                {{
                  record.ExpireDate ? dateShortFormatUTC(record.ExpireDate) : ''
                }}
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      v-model="showModal"
      :title="
        isCreate
          ? lbl['plan-reward-point-earn-manage-create-header']
          : lbl['plan-reward-point-earn-manage-edit-header']
      "
      :footer="null"
      centered
      class="earn-modal"
      @cancel="closeCreate()"
      :class="[currentLocale]"
    >
      <a-form :form="form" @submit.prevent="handleSubmit">
        <!-- Name -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left field-required">
              {{ lbl['plan-reward-point-earn-manage-name'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item>
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: value.name,
                    rules: [
                      {
                        required: true,
                        message:
                          lbl[
                            'plan-reward-point-earn-manage-name-required-message'
                          ],
                      },
                    ],
                  },
                ]"
                :disabled="!isCreate && value.status"
                type="text"
                class="form-control w-100"
              />
            </a-form-item>
          </div>
        </div>

        <!-- Description -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left field-required">
              {{ lbl['plan-reward-point-earn-manage-description'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item>
              <a-textarea
                v-decorator="[
                  'description',
                  {
                    initialValue: value.description,
                    rules: [
                      {
                        required: true,
                        message:
                          lbl[
                            'plan-reward-point-earn-manage-description-required-message'
                          ],
                      },
                    ],
                  },
                ]"
                :disabled="!isCreate && value.status"
                type="text"
                class="form-control w-100"
                :auto-size="{ minRows: 3 }"
              />
            </a-form-item>
          </div>
        </div>

        <!-- Status -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left">
              {{ lbl['plan-reward-point-earn-manage-status'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item class="text-left">
              <a-radio-group
                v-decorator="[
                  'status',
                  {
                    initialValue: value.status,
                  },
                ]"
              >
                <a-radio :value="true" class="mr-4">
                  {{ lbl['plan-reward-point-earn-manage-status-active'] }}
                </a-radio>
                <a-radio :value="false">
                  {{ lbl['plan-reward-point-earn-manage-status-inactive'] }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </div>
        </div>

        <!-- Campaign Period -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left">
              {{ lbl['plan-reward-point-earn-manage-period'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                <a-form-item class="text-left">
                  <a-date-picker
                    v-decorator="[
                      'startdate',
                      {
                        initialValue: value.startDate,
                        rules: [
                          {
                            required: true,
                            message: lbl['smart-target-select-date'],
                          },
                        ],
                      },
                    ]"
                    :disabled="!isCreate && value.status"
                    :format="dateFormat"
                    :show-time="true"
                    :disabled-date="disabledStartDate"
                    @openChange="handleStartOpenChange"
                    @change="onChangeStart"
                  />
                </a-form-item>
              </div>
              <div class="col-xl-1 col-lg-1 col-md-2 col-sm-12 col-12">
                <div class="d-flex align-center justify-center h-default">
                  {{ lbl['plan-reward-stamp-campaign-manage-period-to'] }}
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                <a-form-item class="text-left">
                  <a-date-picker
                    v-decorator="[
                      'enddate',
                      {
                        initialValue: value.endDate,
                        rules: [
                          {
                            required: true,
                            message: lbl['smart-target-select-date'],
                          },
                        ],
                      },
                    ]"
                    :disabled="!isCreate"
                    :open="endOpen"
                    :format="dateFormat"
                    :show-time="true"
                    :disabled-date="disabledEndDate"
                    @openChange="handleEndOpenChange"
                    @change="onChangeEnd"
                  />
                </a-form-item>
              </div>
            </div>
          </div>
        </div>

        <!-- Point Accumulation Ratio -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left">
              {{
                lbl['plan-reward-point-earn-manage-point-accumulation-ratio']
              }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item>
              <div class="div1">
                <a-input-number
                  :min="1"
                  :formatter="
                    value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :parser="value => value.replace(/[^0-9]/g, '')"
                  v-decorator="[
                    'point_ratio',
                    {
                      initialValue: value.point_ratio,
                      rules: [
                        {
                          required: true,
                          message:
                            lbl[
                              'plan-reward-point-earn-manage-point-accumulation-ratio-required-message'
                            ],
                        },
                      ],
                    },
                  ]"
                  :disabled="!isCreate && value.status"
                />
              </div>
              <div class="div1">
                {{ lbl['plan-reward-point-earn-manage-point-ratio'] }}
              </div>
            </a-form-item>
          </div>
        </div>

        <!-- Welcome Point -->
        <div class="row field-row">
          <div
            class="col col-md-3 field-column field-label col-form-label"
            style="position: relative"
          >
            <div class="show-text-lbl-request txt-left">
              {{ lbl['plan-reward-point-earn-manage-welcome-point'] }}
            </div>
          </div>
          <div class="col-md-9 field-column" style="position: relative">
            <a-form-item>
              <div class="div1">
                <a-input-number
                  :min="0"
                  :formatter="
                    value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :parser="value => value.replace(/[^0-9]/g, '')"
                  v-decorator="[
                    'welcome_point',
                    {
                      initialValue: value.welcome_point,
                    },
                  ]"
                  :disabled="disableInputWelcomePoint"
                />
              </div>
              <div class="div1">
                {{ lbl['master-lookup-welcome-point-text'] }}
              </div>
              <div v-if="crmPackage.IsFreePackage" class="noted-upgrade">
                <span>
                  {{ lbl['wanring-upgrade-package-for-traceplan-setting'] }}
                </span>
                <router-link :to="{ name: 'Package' }" class="go-to-upgrade">
                  {{ lbl['click-here-label'] }}
                </router-link>
              </div>
            </a-form-item>
          </div>
        </div>

        <!-- btn submit -->
        <div class="row field-row btnsubmit">
          <a-form-item>
            <a-button type="primary" html-type="submit">
              {{ lbl['plan-reward-point-burn-manage-save-button'] }}
            </a-button>
          </a-form-item>
        </div>
      </a-form>
    </a-modal>
    <ConfirmModal
      :isOpenModal="confirmModalSetting.isOpenModal"
      :modalType="confirmModalSetting.modalType"
      :title="confirmModalSetting.title"
      :details="confirmModalSetting.details"
      :backto="confirmModalSetting.backto"
      :onConfirm="confirmModalSetting.onConfirm"
      :onCancel="confirmModalSetting.onCancel"
    />
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import moment from 'moment'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import CheckBox from './components/CheckBox.vue'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsPlanReward from '@/core/PlanReward/service/BzbsPlanReward'
import ConfirmModal from '@/components/Modal/Component/confirmmodal2'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import Locale from '@/helper/locale.js'

export default {
  name: 'EarnList',
  mixins: [Mixin],
  components: {
    Header,
    CheckBox,
    ConfirmModal,
    AlertModal,
  },
  data: function () {
    return {
      currentLocale: Locale.getLocaleShort(),
      allFilter: {},
      searchInput: '',
      showFilterMain: false,
      subFilter: 'status',
      filter: {
        status: {
          value: null,
          input: ['Active', 'Inactive'],
          label: 'Status',
          type: 'checkbox',
        },
      },
      isLoading: true,
      data: [],
      dataEarn: [],
      currentPage: 1,
      columns: [
        {
          title: 'Rewards',
          dataIndex: 'Name',
          key: 'plan-reward-point-earn-list-name',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Period',
          dataIndex: 'StartDate',
          key: 'plan-reward-point-earn-list-period',
          align: 'center',
          width: '33%',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Baht / Point',
          dataIndex: 'PointRatio',
          key: 'plan-reward-point-earn-list-point-ratio',
          align: 'center',
          width: '33%',
        },
      ],
      disableCreatePointSetup: true,
      showModal: false,
      isCreate: false,
      isEdit: false,
      value: {
        name: '',
        description: '',
        status: true,
        startDate: moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        endDate: moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        point_ratio: 0,
        welcome_point: 0,
      },
      minDate: moment(),
      dateFormat: 'DD MMM YYYY HH:mm:ss',
      endOpen: false,
      RowKey: '',
      currentCampaings: 1,
      totalCampaings: null,
      pageSizeCampaings: 30,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      statusActive: 'Active',
      statusWaiting: 'Waiting',
      crmPackage: null,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'earn-list',
    })
  },
  created() {
    Account.bzbsAnalyticTracking(
      'point_earning_page',
      'point_earn',
      'view_point_earn',
      'on view',
    )
    this.crmPackage = Account.getCachePackage()
    this.init()
    this.setLocale()
  },
  computed: {
    disableInputWelcomePoint() {
      if (this.crmPackage && this.crmPackage.IsFreePackage) {
        return true
      } else {
        if (!this.isCreate && this.value.status) {
          return true
        } else {
          return false
        }
      }
    },
  },
  methods: {
    init() {
      this.handleFooter(true)
      this.statusActive = Vue.bzbsConfig.businessRule.statusText.active
      this.statusWaiting = Vue.bzbsConfig.businessRule.statusText.waiting
      this.getCRMPlusProfile().then(result => {
        console.log('getCRMPlusProfile : ', result)
        if (result.LoginType.toLowerCase() == 'line') {
          this.disableCreatePointSetup =
            !result.DisableCreatePointSetup &&
            result.AppId &&
            (result.LiffId || result.LiffLanding)
              ? false
              : true
        } else {
          this.disableCreatePointSetup =
            !result.DisableCreatePointSetup && result.AppId ? false : true
        }
      })
      this.getCRMPlusEarnCampaings().then(result => {
        console.log('getCRMPlusEarnCampaings : ', result)
        this.dataEarn = result
        this.searchFilter()
      })
    },
    setLocale() {
      _.forEach(this.columns, column => {
        column.title = this.lbl[column.key]
      })
    },
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          window.location.reload()
          this.alertModalSetting.isOpenModal = false
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    getCRMPlusEarnCampaings() {
      this.handleLoading(true)
      return new Promise((resolve, reject) => {
        BzbsPlanReward.getCRMPlusEarnCampaings()
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            reject(error)
          })
      })
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    onChangePageCampaings(page) {
      this.currentCampaings = page
    },
    onChangeStart(date, dateString) {
      this.value.startDate = dateString
      this.startValue = date
    },
    onChangeEnd(date, dateString) {
      this.value.endDate = dateString
      this.endValue = date
    },
    handleStartOpenChange(open) {
      this.value.endDate = ''
      this.endValue = ''
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue
      var date = new Date()

      if (this.isCreate && this.data.length > 0) {
        if (!startValue || !endValue) {
          return startValue.valueOf() < this.getLatestDate()
        }
        return startValue.valueOf() > endValue.valueOf()
      } else {
        if (!startValue || !endValue) {
          return (
            startValue.valueOf() < date.setDate(date.getDate() - 1).valueOf()
          )
        }
        return startValue.valueOf() > endValue.valueOf()
      }
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue
      var date = new Date()
      if (!endValue || !startValue) {
        return endValue.valueOf() < date.setDate(date.getDate() - 1).valueOf()
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    getLatestDate() {
      const timestampArray = this.data
        .filter(i => i.Status !== 'Inactive')
        .map(item => item.ExpireDate)
      const latestTimestamp = Math.max.apply(null, timestampArray)
      return moment.unix(latestTimestamp)
    },
    onEnterInput(event) {
      console.log('onEnterInput : ', event)
      this.searchFilter()
    },
    onChangeInput(event) {
      if (!event.target.value) {
        this.searchFilter()
      }
    },
    toggle(data) {
      this[data] = !this[data]
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    selectFilter(item) {
      console.log('selectFilter :: ', item)
      if (
        (this.filter[this.subFilter].value === 'Active' && item === 'Active') ||
        (this.filter[this.subFilter].value === 'Inactive' &&
          item === 'Inactive')
      ) {
        this.filter[this.subFilter].value = null
      } else {
        this.filter[this.subFilter].value = item
      }
    },
    resetFilter() {
      this.filter.status.value = ''
      this.searchFilter()
    },
    removeFilter(type) {
      Object.keys(this.filter).forEach(item => {
        if (type === item) this.filter[item].value = ''
      })
      this.searchFilter()
    },
    searchFilter() {
      console.log('searchFilter', this.filter.status.value)
      this.showFilterMain = false
      let active = null
      let search = this.searchInput ? this.searchInput.toLowerCase() : null
      if (this.filter.status.value === 'Active') active = true
      else if (this.filter.status.value === 'Inactive') active = false

      this.allFilter = Object.keys(this.filter).reduce((acc, cur) => {
        if (!this.filter[cur].value) return acc
        return (acc = { ...acc, [cur]: this.filter[cur].value })
      }, {})
      if (active != null && search) {
        this.data = _.filter(this.dataEarn, item => {
          if (
            item.Active == active &&
            (item.Name.toLowerCase().includes(search) ||
              item.PointRatio == search)
          )
            return item
        })
        this.totalCampaings = this.data.length
      } else if (active != null && !search) {
        this.data = _.filter(this.dataEarn, item => {
          if (item.Active == active) return item
        })
        this.totalCampaings = this.data.length
      } else if (active == null && search) {
        this.data = _.filter(this.dataEarn, item => {
          if (
            item.Name.toLowerCase().includes(search) ||
            item.PointRatio == search
          )
            return item
        })
        this.totalCampaings = this.data.length
      } else {
        this.data = this.dataEarn
        this.totalCampaings = this.data.length
      }

      this.isLoading = false
    },
    createNew() {
      console.log('createNew')
      Account.bzbsAnalyticTracking(
        'point_earning_page',
        'point_earn',
        'click_point_create',
        'on click',
      )
      this.form.resetFields()
      this.value = {
        name: '',
        description: '',
        status: true,
        startDate: moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        endDate: moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        point_ratio: 0,
        welcome_point: 0,
      }
      this.isCreate = true
      this.showModal = true
      Account.bzbsAnalyticTracking(
        'point_earning_page',
        'point_earn_create',
        'view_create_point',
        'on view',
      )
    },
    closeCreate() {
      this.showModal = false
      Account.bzbsAnalyticTracking(
        'point_earning_page',
        'point_earn_create',
        'click_point_create_close',
        'on click',
      )
    },
    openEdit(record) {
      Account.bzbsAnalyticTracking(
        'point_earning_page',
        'point_earn_edit',
        'click_point_earn_edit_' + record.Name,
        'on click',
      )
      return {
        on: {
          click: () => {
            if (!(record.ExpireDate * 1000).valueOf() < new Date().valueOf()) {
              this.isCreate = false
              this.RowKey = record.RowKey

              this.value.name = record.Name
              this.value.description = record.Description
              this.value.status = record.Active
              this.value.startDate = record.StartDate
                ? moment
                    .unix(record.StartDate)
                    .utc()
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment()
              this.value.endDate = record.ExpireDate
                ? moment
                    .unix(record.ExpireDate)
                    .utc()
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment()
              this.value.point_ratio = record.PointRatio
              this.value.welcome_point = record.WelcomePoint

              this.$nextTick(() => {
                this.showModal = true
              })
            }
          },
        },
      }
    },
    handleSubmit(e) {
      console.log('handleSubmit')
      e.preventDefault()
      this.form.validateFields((err, values) => {
        Account.bzbsAnalyticTracking(
          'point_earning_page',
          'point_earn_create',
          'click_point_create_save',
          'on click',
        )

        if (!err) {
          let params = {
            name: values.name,
            description: values.description,
            active: values.status,
            expiration: values.enddate ? values.enddate != null : false,
            pointRatio: values.point_ratio ? values.point_ratio : 1,
            welcomePoint: values.welcome_point ? values.welcome_point : 0,
            startDate: values.startdate
              ? moment(values.startdate).format('YYYY-MM-DDTHH:mm:ss')
              : null,
            expireDate: values.enddate
              ? moment(values.enddate).format('YYYY-MM-DDTHH:mm:ss')
              : null,
          }
          if (!this.isCreate) {
            params.rowKey = this.RowKey
            this.postUpdate(params)
          } else {
            console.log('params : ', params)
            this.confirmModalAction(
              this.lbl['plan-reward-point-earn-manage-save-confirm-title'],
              this.lbl['plan-reward-point-earn-manage-save-confirm-message'],
              'warning',
              this.lbl['confirm-box-cancel-button'],
              true,
              () => {
                this.confirmModalSetting.isOpenModal = false
                this.postSave(params)
              },
              () => {
                this.confirmModalSetting.isOpenModal = false
              },
            )
          }
        }
      })
    },
    postSave(params) {
      return new Promise((resolve, reject) => {
        BzbsPlanReward.postCRMPlusCreateEarnCampaings(params)
          .then(res => {
            this.success()
            resolve(res.data)
          })
          .catch(error => {
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              this.lbl['earnrewardfailcreatetext'],
              'error',
              true,
            )
            reject(error)
          })
      })
    },
    postUpdate(params) {
      return new Promise((resolve, reject) => {
        BzbsPlanReward.postCRMPlusUpdateEarnCampaings(params)
          .then(res => {
            this.success()
            resolve(res.data)
          })
          .catch(error => {
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            reject(error)
          })
      })
    },
    success() {
      if (!this.isCreate) {
        this.alertModalAction(
          this.lbl['alert-box-success-header'],
          this.lbl['plan-reward-point-earn-manage-edit-success-message'],
          'success',
          true,
        )
      } else {
        this.alertModalAction(
          this.lbl['alert-box-success-header'],
          this.lbl['earnrewardsuccesscreatetext'],
          'success',
          true,
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import './styles/planreward.scss';
@import '@/style/base/common.scss';

.wrap-earnList {
  .wrap-table {
    .btn-sequence {
      padding: 10px;
      width: 40px;
      cursor: move;
      span {
        width: 100%;
        height: 2px;
        display: block;
        background: #000;
        + span {
          margin-top: 2px;
        }
      }
    }
    .box-pagination {
      margin-top: -52px;
      margin-bottom: 20px;
      padding-top: 0;
      .show-page {
        margin-top: 10px;
      }
    }
    .ant-table-wrapper {
      .ant-table-pagination {
        .ant-pagination {
          margin: 16px 0;
        }
      }

      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        font-size: 10px;
      }

      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        font-size: 10px;
        &:hover {
          color: $primary-hover !important;
          border-color: $primary-hover !important;
        }
      }

      .ant-select-dropdown-menu-item:hover,
      .ant-select-dropdown-menu-item-selected {
        background-color: #fff7e6;
      }

      .ant-pagination-item-active,
      .ant-pagination-item:focus,
      .ant-pagination-item:hover {
        border-color: $primary-hover !important;
      }

      i.anticon.anticon-left {
        vertical-align: 0;
      }

      i.anticon.anticon-right {
        vertical-align: 0;
      }
    }
    .ant-input-number {
      height: 40px;
    }
    .ant-input-number-input-wrap {
      height: 100%;
    }
    .ant-input-number-handler-wrap {
      visibility: hidden;
    }
    .ant-input-number-input {
      padding: 0 11px !important;
      height: 100%;
    }
    .form-button {
      text-align: right;
    }
    .status {
      line-height: 24px;
      padding: 0 4px;
      font-size: 14px;
      border-radius: 4px;
      &.active {
        color: $color-active;
        background: $color-bg-active;
      }
      &.inactive {
        color: $color-inactive;
        background: $color-bg-inactive;
      }
    }
    .reward {
      display: flex;
      align-items: center;
      .reward-image {
        margin: 0 5px;
        width: 50px;
        height: 50px;
      }
      .reward-name {
        flex-grow: 1;
        padding-left: 10px;
      }
    }
    .viewcode {
      cursor: pointer;
      text-align: center;
      color: $color-active;
    }
    .disablestyle {
      color: #a6a8ab !important;

      .reward-image {
        opacity: 0.5;
      }
      .viewcode {
        opacity: 0.5;
      }
    }
  }
}
.noted-upgrade {
  color: $btn-danger;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  float: right;
}

.wrap-earnList .ant-pagination-prev .ant-pagination-item-link,
.wrap-earnList .ant-pagination-next .ant-pagination-item-link {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
}
</style>
